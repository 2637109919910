.droptarget {
    float: left; 
    width: 100px; 
    height: 35px;
    margin: 15px;
    padding: 10px;
    border: 1px solid #aaaaaa;
  }

.zoomin_div{
  width: 100%;
  /* width: fit-content; */
  display: flex;
  position: relative;
  height: 40px;
  /* box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2); */
  border: solid 1px #cccccc;
  background-color: #f9f9f9;
  margin-bottom: 6px;
 } 
 .scroller_div{
  cursor: pointer;
  top: -1px;
  width: 12px;
  height: 42px;
  position: absolute;
  background-color: #0087cf;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s left;
 }
 .scroller_div_arrow
 {
  font-size: 10px;
  color: #ffffff;
 }
 .scroller_date{
  font-size: 14px;
  padding: 2px;
  font-size: 14px;
    color: #333333;
    border: solid 1px;
    padding: 2px;
    /* width: 93px; */
    /* height: 30px; */
    border-radius: 3px;
    border: solid 1px #cccccc;
    background-color: #f9f9f9;
 }
 .date_div{
  /* width: 12px; */
  width: inherit;
  height: 40px;
  border-top-width: 0px;
  border-left-width: 0px;
  padding-left: 0;
  padding-right: 0;
  border-bottom-width: 0px;
  border-right: solid 1px #cccccc;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  user-select: none;
  background-color: #f9f9f9;
  outline: 0;

 }

 /* .date_div:hover{
   background-color: blue
 } */

 .right_controller{
   right: 0;
   transition: 0.2s left;
   z-index: 0;
   border-left: 1px solid rgb(204, 204, 204);
 }
 .slider_date_display_div{
  width: 89px;
  height: 28px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  position: absolute;
  bottom: 52px;
  border-radius: 5px;
 }
 .left_active,.right_active{
  background-color: rgba(249, 249, 249, 0) !important;
   z-index: 1;
   position: relative;
   -webkit-user-drag: none !important;
  -khtml-user-drag: none !important;
  -moz-user-drag: none !important;
  -o-user-drag: none !important;
   user-drag: none !important;
 }
 .left_and_right_inner_div{
  position: absolute;
  cursor: pointer;
  height: inherit;
  width: 12px;
  top: 0;
 }