.cov_panel_container{
    padding:51px 60px;
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    /* min-height: calc(100vh - 10vh); */
    height: auto;
}
.cov_filter_div{
    text-align: end;
    font-size: 14px;
    margin-bottom: 22px;
    color: #2c6087;
}
.cov_filter_div > label{
    margin-left: 28px;
    cursor: pointer;
}
.cov_sort_by_dropdown{
    width: 90px;
    height: 24px;
    border-radius: 3px;
    border: solid 1px #66b7e2;
    background-color: #ffffff;
    outline-style: none;
    margin-left: 4px;
}
.cov_sort_by_dropdown > option{
    font-size: 11px;
    color: #666666;
    border-bottom: solid 2px #66b7e2;
}