.card_relation_content_main_div{
    /* padding: 0 10px; */
    /* background-color: rgb(249, 249, 249); */
    /* padding-top: 17px; */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.card_relation_content{
    overflow: auto;
}
.card_relation_content::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

/* Track */
.card_relation_content::-webkit-scrollbar-track {
    border-radius:5px;
}
/* Handle */
.card_relation_content::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}