.cov_panel_container {
  padding: 20px 22px;
}
.journey_header {
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
}
.journey_events_main_div {
  height: 30px;
  border-radius: 15px;
  background-color: rgba(255, 134, 51, 0.4);
  position: absolute;
  padding: 0 17px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  right: 0;
}
.journey_header_color {
  color: #f47f34;
}
.journey_header_font_size {
  font-size: 20px;
  margin-right: 19px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f47f34; */
  border-radius: 4px;
  cursor: pointer;
}
.journey_active_div {
  background-color: #f47f34;
  color: white;
}
/* .journey_legend_main_div{
    margin: 48px 110px;
    display: flex;
    height: auto;
    align-items: center;
} */
.journey_legend_heading_main_div {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 29px;
}
.journey_legend_heading_main_div > img {
  margin-right: 9px;
}
/* .chartjs-render-monitor{
    height: 545px !important;
} */

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
/* table, th, td {
  border: 1px solid black;
} */
.tbb {
  display: inline-block;
  border: 1px solid black;
}
.bx {
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sentiscore {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.journey_tree_main_div {
  height: auto;
  width: 431px;
  position: relative;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5) !important;
  background-color: #f9f9f9 !important;
}
.journey_tooltip_header_main_div {
  background-color: white;
  padding: 0 20px;
  height: 50px;
  align-items: center;
  /* width: 100%; */
  border-bottom: solid 1px #cccccc;
  display: flex;
  font-family: 'Open Sans';
}

.journey_carrot_shadow {
  font-size: 35px;
  color: white;
  /* box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.5); */
  text-shadow: 2px 1px 2px #b3aeae;
  z-index: 0;
  line-height: 7px;
  right: -14px;
  top: 9px;
  /* bottom: -7px; */
  border: solid transparent;
  content: ' ';
  height: fit-content;
  width: fit-content;
  position: absolute;
  margin-left: -13px;
}
.popup_main_div {
  width: 600px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 6px 14px 3px rgba(0, 0, 0, 0.2);
}
.popup_header {
  background-color: #f9f9f9 !important;
  height: 50px;
  font-size: 15px;
  padding: 0;
  display: flex;
  align-items: center;
  padding: 0 31px;
  border-bottom: 1px solid #cccccc;
  /* border-radius: 0 !important; */
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  box-shadow: unset !important;
}
.popup_header_text {
  font-size: 15px !important;
  font-weight: bold !important;
  color: #000000;
  font-family: 'Open Sans', sans-serif !important;
  margin-left: 9px !important;
  text-transform: capitalize;
}
.popup_header_logo {
  color: #2c6087;
  font-size: 20px;
  margin-right: 11px;
}
.popup_header_cancel {
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  right: 31px;
}
.popup_footer_main_div {
  height: 50px;
  background-color: #f9f9f9;
  border-top: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: flex-end;
}
.popup_footer_button {
  width: auto;
  height: 33px;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif !important;
}
.popup_footer_cancel_button {
  background-color: #d8d8d8 !important;
  color: #000000 !important;
}
.popup_footer_apply_button {
  background-color: #f47f34 !important;
  color: white !important;
  margin-left: 10px !important;
}
.popup_content__body {
  display: grid;
  grid-template-columns: 50% 50%;
}
.popup_contnet_body_result_div {
  font-size: 15px;
  font-weight: 600;
  color: #2c6087;
  justify-content: flex-end;
}
.popup_hgt_align_center {
  height: 35px;
  /* align-items: center; */
  display: flex;
  position: relative;
}
.popup_hgt_align_center > span {
  position: absolute;
  right: 0;
}
.popup_hgt_align_center > label {
  font-size: 15px;
  letter-spacing: normal;
  color: #000000;
}

.dialog-content {
  max-height: 550px;
  margin: 0px 10px;
}

.dialog-content::-webkit-scrollbar {
  width: 6px;
  background-color: white;
  border-radius: 6px !important ;
}

/* Track */

.circle_notification {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  position: absolute;
  background-color: #f47f34;
  border: 4px solid #ffc6a1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.senti_gradient_axis {
  width: 6px;
  position: absolute;
  height: 541px;
  z-index: 0;
  left: 0;
  top: 7px;
  background-image: linear-gradient(
    to bottom,
    #46cc46,
    #7ad34a 13%,
    #f4a634 23%,
    #f47f34 32%,
    #f35a5a 42%,
    #f35a5a 52%,
    #e94747 63%,
    #e03434 72%,
    #e03434 81%,
    #d82323 90%,
    #c70000
  );
}
.sentimeter_scoretitle {
  transform: rotate(-90deg);
  width: 140px;
  position: absolute;
  left: -82px;
}
.senti_tooltip_ {
  width: 420px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  background-color: #f9f9f9;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
}
.senti_event_tooltip_ {
  width: 310px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  background-color: #f9f9f9;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
}
.senti_tooltip_after {
  display: block;
  z-index: 2;
  line-height: 7px;
  /* left: 50%; */
  bottom: -7px;
  border: solid transparent;
  content: ' ';
  height: fit-content;
  width: fit-content;
  position: absolute;
  margin-left: -13px;
}
.sentimeter_carrot_shadow {
  font-size: 35px;
  color: white;
  /* box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.5); */
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  text-shadow: 0px 2px 2px #b3aeae;
}
.sentimeter_tooltip_header_main_div {
  height: auto;
  border-bottom: solid 1px #cccccc;
  padding: 10px 20px;
  display: flex;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.sentimeter_event_tooltip_header_main_div {
  height: auto;
  border-bottom: solid 1px #cccccc;
  padding: 10px 20px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.sentimeter_high_light_innerDiv {
  width: 100%;
  display: flex;
  margin-left: 18px;
  margin-right: 30px;
  position: relative;
}
.sentimeter_tooltip_header_main_div_score {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bold;
}
.sentimeter_event_main_div {
  width: 81%;
}
.sentimeter_event_label_heading {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}
.sentimeter_event_label_result {
  font-size: 13px;
  color: #000000;
  margin-left: 5px;
  word-break: break-word;
}

.sentimeter_high_light_content_main_div {
  max-height: 272px;
  overflow: auto;
}
.sentimeter_high_light_content_main_div::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

/* Track */
.sentimeter_high_light_content_main_div::-webkit-scrollbar-track {
  border-radius: 5px;
}
/* Handle */
.sentimeter_high_light_content_main_div::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}

.sentimeter_high_light_content {
  /* height: 50px; */
  width: inherit;
  border-bottom: solid 1px #ccc;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000000;
  padding: 6px 0;
}
.sentimeter_carrot_up_shadow {
  font-size: 35px;
  color: white;
  /* box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.5); */
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  text-shadow: 0px -2px 2px #b3aeae;
}
.sentimeter_emoji_main_div {
  width: 20%;
  display: flex;
  align-items: center;
}
.sentimeter_LHL_header {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  /* width: fit-content; */
  position: absolute;
  top: 3px;
  /* height: inherit; */
  right: 7px;
  padding-left: 10px;
}
.sentimeter_zoom_back_btn {
  width: 50px;
  height: 22px;
  border-radius: 4px;
  -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
  margin-top: 14px !important;
  width: 50px;
  min-width: 0px !important;
  position: absolute !important;
  align-items: center !important;
  padding: 0 !important;
}
.sentimeter_zoom_back_btn > span {
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: capitalize;
}
.sentimeter_high_light_content:nth-last-child(1) {
  border-bottom: 0 !important;
}
.sentimeter_dropdown {
  margin-left: 20px;
  width: 90px;
  height: 24px;
  border-radius: 3px;
  border: solid 1px #66b7e2;
  background-color: #ffffff;
  outline-style: none;
}
.sentimeter_policy_status {
  font-size: 12px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.sentimeter_status_color {
  height: 14px;
  width: 14px;
  margin-right: 7px;
  border-radius: 50%;
}
.redo_tooltip_class {
  margin: 0 !important;
}
.sentimeter_reset_icon {
  font-size: 20px;
  width: 36px;
  /* height: 36px; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.no-change {
  width: 0;
  height: 0;
  border-left: 5px solid grey;
  border-right: 5px solid grey;
  border-bottom: 9px solid grey;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 6px;
}
.sentimeter_header_color {
  color: #f47f34;
}
.sentimeter_switch_label {
  font-size: 15px;
  width: max-content;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.sentimeter_timeline_filter {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
  text-align: end;
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.sentimeter_timeline_filter_date {
  margin-left: 10;
  min-width: 30px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}
/* #line_canvas{
    height: auto !important;
} */
/* .sentimeter_event_main_div > :first-child,:last-child{
    text-align: center;
} */
