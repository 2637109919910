.sticky {
    position: absolute;
    top: 0;
    background-color: white;
    width: 100%;
    left:0;
    box-shadow:0 0px 5px grey
  }
  
  .sticky + .content {
    padding-top: 60px;
  }

  #cov_summary_card{
    scroll-behavior: smooth;
  }