.relation_filter_panel{
    width: 497px;
    height: 652px;
    border-radius: 6px;
    box-shadow: -3px 0 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    transition: right 0.2s;
}
.relation_filter_main_div{
    height: 63px;
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.relation_filter_head_button_div{
    position: absolute;
    right: 20px;
}
.relationn_filter_heading{
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}
.relation_button{
    width: auto;
    height: 33px;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: "Open Sans", sans-serif !important;
}
.relation_cancel_button{
    background-color: #d8d8d8 !important;
    color: #000000 !important;
}
.relation_apply_button{
    background-color: #f47f34 !important;
    color: white !important;
    margin-left: 10px !important;
}
.relation_serach_main_div{
    height: 40px;
    border-bottom: solid 1px #cccccc;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
.serach-field::placeholder{
    font-size: 15px;
    color: #999999;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: normal;
}
.relation_search_icon{
    color: #cccccc;
    position: absolute;
    right: 20px;
}   
.relation_filter_div{
    height: calc(100% -  105px);
    display: flex;
    overflow-y: scroll;
}

.relation_filter_div::-webkit-scrollbar {
    width: 6px;
    background-color: #ccc;
}

.relation_filter_label_div{
    width: 200px;
    /* height: 717px; */
    background-color: #eeeeee;
}
.relation_filter_label_inner_div{
    width: 154px;
    line-height: 50px;
    padding: 0 20px;
    color: #333333;
    font-size: 14px;
    cursor: pointer;
    background-color: #eeeeee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}
.relation_active_tab{
    background-color: white;
    color: #000000;
    font-weight: 600;
}
.reltion_filter_value{
    margin: 0 !important;
    height: auto;
}
.reltion_filter_value > .MuiFormControlLabel-label{
    font-size: 14px;
    color: #333333;
    word-break: break-all;
}