.relation_card_title {
  font-size: 12px;
  font-weight: 600;
  margin-top: 22px;
  padding-bottom: 6px;
}
.relation_exapnd_card_title {
  font-size: 16px !important;
}
.relation_card_panel {
  padding: 11px 5px;
  /* height: 150px; */
  display: flex;
  justify-content: center;
  text-align: center;
}
.relation_panel_heading {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
}
.relation_panel_info {
  font-size: 13px;
  letter-spacing: normal;
  color: #666666;
  font-weight: bold;
}
.metrics_extra_info {
  font-size: 13px;
  margin-top: 12px;
  color: #666666;
}

.relation_panel_info_mar {
  margin-top: 10px;
}
.content_header {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
.content_header_bold {
  font-weight: 700;
}
.relation_date_logo {
  font-size: 17px;
  color: #4cabdd;
}
.relation_text_align {
  padding-left: 8px;
  vertical-align: top;
}
.relation_bullet_wdt_hgt {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 7px;
}
.relation_legend_div {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}
.relation_legend_sub_div {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.relation_legend_label {
  font-size: 12px;
}
.relation_green_bullet {
  background-image: linear-gradient(to bottom, #9cdd9c, #52ab52);
}
.relation_red_bullet {
  background-image: linear-gradient(to bottom, #e58282, #d15959);
}
.relation_black_bullet {
  background-image: linear-gradient(to bottom, #cccccc, #666666);
}
.relation_grid_column_mian_div {
  align-items: center;
  display: flex;
}
.relation_grid_label {
  width: 60%;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  color: #000000;
  word-break: break-word;
}
.relation_grid_colon {
  width: 8.3333%;
  text-align: center;
}
.relation_card_switch_label {
  float: right;
  width: 8.3333%;
  padding: 0;
}
.popup_main_div {
  width: 720px !important;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 6px 14px 3px rgba(0, 0, 0, 0.2);
}

.popup_content__body::-webkit-scrollbar {
  width: 6px;
  background-color: white;
  border-radius: 6px !important ;
}
/* Track */
.popup_content__body::-webkit-scrollbar-track {
  border-radius: 5px;
}
/* Handle */
.popup_content__body::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}

.popup_header {
  background-color: #f9f9f9 !important;
  height: 50px;
  font-size: 15px;
  padding: 0;
  display: flex;
  align-items: center;
  padding: 0 31px;
  border-bottom: 1px solid #cccccc;
  border-radius: 0 !important;
  box-shadow: unset !important;
}
.popup_header_text {
  font-size: 15px !important;
  font-weight: bold !important;
  color: #000000;
  font-family: 'Open Sans', sans-serif !important;
}
.popup_header_logo {
  color: #2c6087;
  font-size: 17px;
  margin-right: 0px;
}
.popup_header_cancel {
  font-size: 20px;
  position: absolute;
  right: 31px;
  cursor: pointer;
}
.popup_footer_main_div {
  height: 50px;
  background-color: #f9f9f9;
  border-top: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: flex-end;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.popup_footer_button {
  width: auto;
  height: 33px;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif !important;
}
.popup_footer_cancel_button {
  background-color: #d8d8d8 !important;
  color: #000000 !important;
}
.popup_footer_apply_button {
  background-color: #f47f34 !important;
  color: white !important;
  margin-left: 10px !important;
}
.popup_content__body {
  display: grid;
  grid-template-columns: 50% 50%;
}
.relation_expanded_main_div {
  display: flex;
  /* align-items: center; */
  padding: 0 15px;
}
.relation_expanded_content_div {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  color: #000000;
  width: 50%;
}
.relation_expanded_label {
  text-align: left;
  position: relative;
  word-break: break-word;
}
.relation_expanded_result {
  text-align: right;
  color: #2c6087;
  font-weight: 600;
}
.relation_basic_info_div {
  display: block;
  position: absolute;
  /* left: 48px; */
  top: 156px;
  transition: left 0.2s, top 0.2s;
}
.customer_relation_img_main_div {
  text-align: center;
  color: white;
  width: 144px;
  position: relative;
  display: flex;
  padding-bottom: 15px;
}
.relation_img_div {
  width: -webkit-fill-available;
  right: auto;
}
.relation_panel_expanded_heading {
  color: white;
}
.backdrop {
  position: absolute;
  height: 728px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: 1;
}
.relation_reset {
  height: 18px;
  border-radius: 15px;
  border: solid 1px #cccccc;
  background-color: #f9f9f9;
  width: fit-content;
  text-align: center;
  font-size: 12px;
  color: #666666;
  margin-left: 10px;
  margin-bottom: 5px;
}
.relation_reset_cancel_icon {
  color: #666666;
  padding-right: 5px;
  padding-left: 5px;
}
.info_div_header .relation_panel_heading {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.info_div_header .relation_panel_info {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  font-weight: bold;
}
.info_div_header .relation_date_logo {
  color: #ffffff !important;
}
.info_div_header .metrics_extra_info {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}
