.customer_main_div {
  height: fit-content;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background-color: white;
  margin-top: 65px;
}

.cs_color {
  color: red;
}

.customer_info_sentence_div {
  width: 100% !important;
  text-align: left !important;
}

.customer_sentence_number {
  color: #2c6087;
  font-weight: 600;
  margin-right: 5px;
}

.customer_info_grid {
  height: auto;
  /* padding: 0px 45px; */
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  /* padding-bottom: 25px; */
  /* margin-left: 115px;
    margin-right: 35px; */
}

.customer_all_info_div {
  height: auto;
  width: 100%;
}

.customer_info_grid_div {
  width: auto;
  /* height: 340px; */
  border-radius: 6px;
  border: solid 1px #66b7e2;
  background-color: #ffffff;
  margin-bottom: 60px;
  margin-left: 10px;
  position: relative;
  margin-right: 10px;
  padding: 32px 20px;
  padding-right: 11px;
  padding-bottom: 0px;
}

.customer_info_top_grid_div {
  width: auto;
  height: 130px;
  border-radius: 6px;
  border: solid 1px #66b7e2;
  background-color: #ffffff;
  margin-bottom: 60px;
  margin-left: 10px;
  position: relative;
  margin-right: 10px;
  padding: 32px 20px;
  padding-right: 11px;
  padding-bottom: 0px;
}

.customer_div_head {
  position: absolute;
  top: -12px;
  width: auto;
  padding: 0 9px;
  height: 27px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  left: 11px;
}

.customer_info_row {
  display: flex;
  position: relative;
  line-height: 2.5;
  font-size: 16px;
}

.customer_info_label {
  width: 43%;
  position: relative;
  word-break: break-word;
}

.customer_div_dot {
  position: absolute;
  right: 0px;
  top: 0;
}

.customer_info_result_main_div {
  width: 57%;
  position: relative;
  height: fit-content;
  text-align: right;
}

.customer_info_result {
  /* position: absolute; */
  /* right: 0px; */
  /* margin-right: 13px; */
  font-weight: 600;
  color: #2c6087;
  word-break: break-word;
}

.customer_has_tags_div {
  width: fit-content;
  /* height: 34px; */
  border-radius: 17px;
  background-color: #4facde;
  padding: 0 14px;
  /* display: flex; */
  align-items: center;
  color: white;
  font-size: 16px;
  margin-right: 10px;
  float: left;
  margin-top: 10px;
  word-break: break-all;
}

.customer_main_info_div_290 {
  height: 290px;
  overflow: scroll;
}

.customer_main_info_div_170 {
  height: 170px;
  overflow: scroll;
}

.customer_main_info_div_170::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

.customer_no_data_available {
  margin: auto auto;
  height: 100%;
  display: flex;
  align-items: center;
  /* position: absolute; */
  width: fit-content;
}

/* Track */
.customer_main_info_div_170::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
.customer_main_info_div_170::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}

.customer_main_info_div_290::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

/* Track */
.customer_main_info_div_290::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
.customer_main_info_div_290::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}

.customer_main_info_div::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

/* Track */
.customer_main_info_div::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
.customer_main_info_div::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}

.customer_relation_img_main_div {
  text-align: center;
  /* font-size: 54px; */
  color: white;
}

.customer_relation_img {
  font-size: 54px;
}

.customer_lifestage_label_color {
  color: #2c6087 !important;
  font-size: 16px;
  font-weight: 600;
}

.customer_lifestage_result_color {
  font-size: 14px !important;
  font-weight: normal !important;
}

.customer_switch_box {
  float: right;
  right: 90px;
  top: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.customer_switch_selected {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.customer_switch_unselect {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
}

.customer_lifestage_div {
  word-break: break-all;
  overflow: scroll;
  height: inherit;
  overflow-x: hidden;
}

.customer_lifestage_div::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

/* Track */
.customer_lifestage_div::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
.customer_lifestage_div::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}