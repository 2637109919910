.metrics_card_title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 17px;
  margin-bottom: 17px;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.metrics_card_panel {
  padding: 11px;
  text-align: center;
  /* scrollbar-width: thin; */
}
.popup_content__body::-webkit-scrollbar {
  width: 6px;
  background-color: white;
  border-radius: 6px !important ;
}
/* Track */
.popup_content__body::-webkit-scrollbar-track {
  border-radius: 5px;
}
/* Handle */
.popup_content__body::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}
.metrics_panel_heading {
  font-size: 20px;
  font-weight: bold;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
  width: 100%;
  overflow: auto;
}
.metrics_panel_heading::-webkit-scrollbar {
  display: none;
}

.font_size_17px {
  font-size: 17px;
}

.metrics_extra_info {
  font-size: 13px;
  margin-top: 12px;
  color: #666666;
}

.metrics_card_Date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 14px;
}

.metrics_panel_info {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 14px;
  height: 36px;
}
.metrics_date_logo {
  font-size: 17px;
  color: #4cabdd;
}
.metrics_text_align {
  padding-left: 8px;
  vertical-align: top;
}
.customer_metric_img_main_div {
  text-align: center;
  /* font-size: 54px; */
  color: white;
}
.backdrop {
  position: absolute;
  height: 728px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: 1;
}
.groupPanel {
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
}
.groupKey {
  width: 50%;
  text-align: left;
  color: black;
}
.groupValueEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.groupValue {
  width: 50%;
  text-align: right;
  word-break: break-word;
  color: rgb(44 96 135);
  font-weight: 600;
}
