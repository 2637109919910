#gridContainer .ag-theme-alpine {
  font-family: 'Barlow', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"',
    '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"',
    '"Segoe UI Emoji"', '"Segoe UI Symbol"';
}

#gridContainer .ag-theme-alpine .ag-root-wrapper {
  border-radius: 5px;
}

#gridContainer
  .ag-theme-alpine
  .ag-ltr
  .ag-side-bar-left
  .ag-tool-panel-wrapper {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0;
}

/* Column Header */
#gridContainer .ag-theme-alpine .ag-header {
  background: #ffffff;
  border-bottom: 1px solid #d1dee6;
}

/* Row */
#gridContainer .ag-theme-alpine .ag-row {
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
  background: #ffffff;
  border-bottom: 1px solid #d1dee6;
}

/* Grid Cells */
#gridContainer .ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  /* display: flex; */
  align-items: center !important;
  font-weight: 600;
  font-size: 12px;
  color: #8da9bf;
}

#gridContainer .ag-theme-alpine .ag-header-cell {
  /* display: flex; */
  align-items: center !important;
}

/* wrap column header */
#gridContainer .ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

#gridContainer .ag-theme-alpine .ag-ltr .ag-cell {
  display: flex;
  align-items: center;
}

#gridContainer .ag-theme-alpine .ag-ltr .ag-cell > img {
  padding-right: 8px;
}

.center-aligned-header .ag-header-cell-label .ag-header-cell-text {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.center-aligned-cell.ag-cell-value {
  justify-content: center;
}

.left-aligned-cell.ag-cell-value {
  justify-content: start;
}

.right-aligned-cell.ag-cell-value {
  justify-content: end;
}

/* Sidebar */
#gridContainer .ag-theme-alpine .ag-ltr .ag-side-bar-left {
  border-radius: 5px;
}

/* Toolpanel */
#gridContainer .ag-theme-alpine .ag-tool-panel-wrapper {
  min-width: 250px;
}

/* Filter Panel */
#gridContainer .ag-theme-alpine .ag-filter-toolpanel-header {
  font-weight: 600;
  color: #4a4a4a !important;
  background: #ffffff;
}

#gridContainer .ag-theme-alpine .ag-filter-apply-panel {
  /* display: none; */
  border-top: none;
}

/* #gridContainer .ag-theme-alpine .ag-filter-apply-panel:first-of-type {
  display: none;
  border-top: none;
} */

#gridContainer
  .ag-theme-alpine
  .ag-filter-apply-panel
  > .ag-filter-apply-panel-button {
  /* display: none; */
}

#gridContainer
  .ag-theme-alpine
  .ag-filter-apply-panel
  > .ag-filter-apply-panel-button
  ~ .ag-filter-apply-panel-button {
  display: block;
  border-top: none;
}

#gridContainer .ag-theme-alpine .ag-filter-toolpanel-instance-filter {
  border-left: none;
  background: #ffffff;
}

#gridContainer .ag-theme-alpine .ag-filter-toolpanel-group-container {
  background: #ffffff;
}

#gridContainer .ag-theme-alpine .ag-filter-apply-panel-button {
  line-height: 1.5;
  border: none;
  color: #4c94ec !important;
  font-size: 12px;
}

#gridContainer
  .ag-theme-alpine
  .ag-filter-toolpanel-instance-filter
  > .ag-filter-wrapper
  > .ag-filter-apply-panel
  > .ag-filter-apply-panel-button {
  display: none;
}

#gridContainer
  .ag-theme-alpine
  .ag-filter-toolpanel-instance-filter
  > .ag-filter-wrapper
  > .ag-filter-apply-panel
  > .ag-filter-apply-panel-button
  ~ .ag-filter-apply-panel-button {
  display: block;
  border-top: none;
}

#gridContainer .ag-theme-alpine .ag-filter-toolpanel-search {
  height: 65px;
  align-items: flex-end;
  margin: 5px 0px;
}

#gridContainer .ag-theme-alpine .ag-tool-panel-wrapper {
  overflow: unset;
}

#gridContainer .ag-theme-alpine .ag-filter-list-panel {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 80px);
}

/* Columns Panel */
#gridContainer .ag-column-select-list {
  background: #ffffff;
}

#gridContainer .ag-column-select-column {
  color: #4a4a4a;
  font-weight: 600;
  font-size: 12px;
}

#gridContainer .ag-theme-alpine .ag-virtual-list-item {
  border-bottom: 1px solid #d1dee6;
}

/* Filter Container */
#gridContainer .ag-theme-alpine .ag-react-container {
  width: 320px;
}

/* No Rows Overlay */
#gridContainer
  .ag-theme-alpine
  .ag-overlay
  .ag-overlay-panel
  .ag-overlay-wrapper
  .ag-react-container {
  width: 100%;
  height: 454px;
}
