.household_main_div{
    width: 100%;
    height:100%;
    border: solid 1px #66b7e2;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin-top: 53px;
}
.household_heading_main_div{
    height: 128px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: solid 1px #66b7e2;
    background-color: #ffffff;
    padding: 0px 26px;
}
.household_heading{
    display: flex;
    justify-content: center;
}
.household_heading_name_div{
    width: 325px;
    height: 65px;
    border-radius: 7px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: #66b7e2;
    position: relative;
    padding: 0 10px;
}
.household_heading_name{
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    height: inherit;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 7px;
}
.household_home_log{
    width: 80px;
    height: 80px;
    /* border: solid 1px #66b7e2; */
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -56px;
    /* padding: 12px; */
    left: calc(50% - 14%);
    /* margin: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.household_home_logo_inner_div{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: solid 1px #66b7e2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.household_logo{
    color: #2c6087;
    font-size: 27px;
}
.household_links_main_div{
    display: flex;
    height: 63px;
    align-items: center;
    position: relative;
}
.household_customers_links{
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}
.household_link_type_main_div{
    position: absolute;
    right: 0;
}
.household-check-box > .MuiIconButton-label > svg{
    width: 17px !important;
    height: 17px !important;
}

.PrivateSwitchBase-root-97{
    padding: 0px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
}
.household_tree_main_div{
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.top-bottom{
    text-align: center;
vertical-align: middle;
}
.tooltip_zindex{
    width: 420px;
    height: auto;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    z-index: 0;
    border-radius: 6px;
}
.tooltip_after{
    display: block;
    z-index: 0;
    line-height: 7px;
    left: 50%;
    bottom: -7px;
    border: solid transparent;
    content: " ";
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin-left: -13px;
}
.carrot_shadow{
    font-size: 35px;
    color: white;
    /* box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.5); */ 
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
    text-shadow: 0px -2px 2px #b3aeae;
}
.go_to_cov_div{
    width: auto;
    height: 30px;
    border-radius: 15px;
    position: absolute;
    background-color: grey;
    right: 38px;
    display: flex;
    align-items: center;
    padding: 0 11px;
    cursor: pointer;
}
.go_to_cov_div_text{
    font-size: 14px;
    font-weight: bold;
    color: white;
}
.got_to_cov_arrow{
    margin-left: 10px;
    color: white;
    font-size: 12px;
}
.tooltip_customer_name{
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px 18px;
    background-color: white;
    /* border-top-left-radius: 4px; */
    border-bottom: solid 1px #cccccc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.tooltip_customer_name > #nnid{
    width: calc(100% - 33%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
}
.tooltip_customer_id{
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    border-bottom: solid 1px #cccccc;
    word-break: break-all;
}
.tooltip_relationships_id{
    display: flex;
    padding: 0 18px;
    /* height: 120px; */
    padding-top: 11px;
}
.tooltip_label{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    width: 380px;
}
.tooltip_result_logo{
    font-size: 14px;
    color: #f47f34;
    margin-right: 10px;
}
.tooltip_relationship_result{
    margin-bottom: 20px;
}
.bottom-center{
    
}