input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    background-clip: content-box;
    border: 2px solid #bbb;
    background-color: #e7e6e7;
    border-radius: 50%;
    margin: 6px 4px 0px 4px;
  }
  

  input[type="radio"]:checked {
    background-color: #2651e0;
  }
